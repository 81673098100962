export const SidebarData = [
    {
        title: 'Home',
        path: '/',
        cName: 'nav-text'
    },
    // {
    //     title: 'About',
    //     path: '/about',
    //     cName: 'nav-text'
    // },
    // {
    //     title: 'Projects',
    //     path: '/projects',
    //     cName: 'nav-text'
    // },
    // {
    //     title: 'Blog',
    //     path: '/blog',
    //     cName: 'nav-text'
    // },
    {
        title: 'Resume',
        path: '/resume',
        cName: 'nav-text'
    },
    // {
    //     title: 'Contact',
    //     path: '/contact',
    //     cName: 'nav-text'
    // }
    // {
    //     title: 'More To Come',
    //     path: '/',
    //     cName: 'nav-text'
    // }

]