import React, { Component } from 'react'

import './components-css/BlogTile.css'

export default class BlogTile extends Component {
    render() {
        return (
            <div className='blog-tile-container'>
                <img src='./img/api1.jpg' alt='' className='blog-tile-image' />
                <h2 className='blog-tile-title'>Article Title That Could Be Long</h2>
                <p className='blog-tile-description'>A brief description of what the blog post is going to be about. This will help get the user excited and ready to learn more about whatever it is I'm talking about</p>
                <p className='blog-tile-technologies'>Technologies: React, Redux, Netlify</p>
                <a href='/#/about' className='blog-tile-read-more-link'><h5 className='blog-tile-read-more-text'>Read More</h5></a>
            </div>
        )
    }
}
