import React, {useState} from 'react';
import './pages-css/Resume.css';
import {ExperienceData} from '../components/data/ExperienceData';
import LeftBar from '../components/LeftBar';
import { CSSTransition } from 'react-transition-group';

export default function Resume() {


    const [appearResume] = useState(true);

    return (
        <CSSTransition
            in={appearResume}
            appear={true}
            timeout={300}
            classNames="fade"
        >
            <div className='clearfix resume'>
                <div className='contact-info'>
                    <a href='mailto:hello@ryanluttrell.ca' target='_blank' rel="noopener noreferrer" className='resume-details'><p className='resume-email'>hello@ryanluttrell.ca</p></a>
                    <a href='https://www.google.ca/maps/place/Calgary,+AB/@51.0275396,-114.2279159,11z/data=!3m1!4b1!4m5!3m4!1s0x537170039f843fd5:0x266d3bb1b652b63a!8m2!3d51.0447331!4d-114.0718831' target='_blank' rel="noopener noreferrer" className='resume-details'><p className='resume-location'>Calgary, AB</p></a>
                    <a href='https://www.linkedin.com/in/ryan-luttrell/' target='_blank' rel="noopener noreferrer" className='resume-details'><p className='resume-linkedin'>LinkedIn</p></a>
                    <a href='https://www.instagram.com/standardryan/' target='_blank' rel="noopener noreferrer" className='resume-details'><p className='resume-instagram'>Instagram</p></a>
                    <a href='https://github.com/RyanLuttrell' target='_blank' rel="noopener noreferrer" className='resume-details'><p className='resume-github'>GitHub</p></a>
                </div>

                <div className='resume-education'>
                    <h4>Education:</h4>
                    <div className='resume-education-container'>
                        <h3 className='resume-education-location'>University of Prince Edward Island</h3>
                        <p className='resume-education-degree'>Bachelor of Science (Engineering)</p>
                        <p className='resume-education-time'>2009-2013</p>
                    </div>
                    <div className='resume-education-container'>
                        <h3 className='resume-education-location'>Team Treehouse</h3>
                        <p className='resume-education-degree'>Full-Stack JavaScript TechDegree</p>
                        <p className='resume-education-time'>2020</p>
                    </div>
                    <h4>Certifications:</h4>
                    <div className='resume-education-container'>
                        <h3 className='resume-certification-location'>Google Analytics Individual Qualification</h3>
                        <p className='resume-certification-year'>2020</p>
                    </div>
                    <div className='resume-education-container'>
                        <h3 className='resume-certification-location'>Google Ads Display Certification</h3>
                        <p className='resume-certification-year'>2020</p>
                    </div>
                </div>


                <div className='resume-experience'>
                    <h4>Experience</h4>
                    { ExperienceData.map((role, index) => {
                        return(
                            <div key={index} className='resume-experience-single'>
                                <h3 className='resume-role-title'>{role.title}</h3>
                                <p className='resume-role-extra'><a href={role.companyLink} target='_blank' rel="noopener noreferrer" className='resume-role-link'>{role.company}</a> | {role.location} | {role.dates}</p> 
                                <p className='resume-role-description'>{role.description}</p>
                                <ul className='resume-duty-ul'>
                                    {
                                        role.duties.map((duty, index) => {
                                            return (
                                                <li key={index} className='resume-duty'>
                                                    {duty}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        )
                    })
                    }
                </div>
                <LeftBar />

            </div>
        </CSSTransition>
    )
}