import React from 'react';
import './components-css/Footer.css'

export default function Footer() {

    return (
        <div className='main-footer'>
            <h6>All rights reserved Ryan Luttrell &#169; 2020</h6>
        </div>
    )
}