import React, { Component } from 'react'

import BlogTile from '../components/BlogTile.jsx'

// Import database API calls
import {
    createBlog,
    readAllBlogs,
    updateBlog,
    deleteBlog,
    batchDeleteBlogs
} from '../utils/blogAPI'

export default class BlogArchive extends Component {

    state = {
        blogs: []
    }

    componentDidMount() {
        readAllBlogs()
            .then(result => this.setState({
                blogs: result
            }))
            .catch(error => this.setState({
                blogs: ''
            }))
            
    }

    render() {
        return (
            <div>
                <BlogTile />
                <BlogTile />
                <BlogTile />
                <BlogTile />
                <BlogTile />
                <BlogTile />
                <BlogTile />
                <BlogTile />
            </div>
        )
    }
}
