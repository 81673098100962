/* Api methods to call /functions */

const createBlog = (data) => {
    return fetch('/.netlify/functions/create_blog', {
      body: JSON.stringify(data),
      method: 'POST'
    }).then(response => {
      return response.json()
    })
  }
  
  const readAllBlogs = () => {
    return fetch('/.netlify/functions/read_all_blogs').then((response) => {
      return response.json()
    })
  }
  
  const updateBlog = (BlogId, data) => {
    return fetch(`/.netlify/functions/update_blog/${BlogId}`, {
      body: JSON.stringify(data),
      method: 'POST'
    }).then(response => {
      return response.json()
    })
  }
  
  const deleteBlog = (BlogId) => {
    return fetch(`/.netlify/functions/delete_blog/${BlogId}`, {
      method: 'POST',
    }).then(response => {
      return response.json()
    })
  }
  
  const batchDeleteBlogs = (BlogIds) => {
    return fetch(`/.netlify/functions/delete_blog_batch`, {
      body: JSON.stringify({
        ids: BlogIds
      }),
      method: 'POST'
    }).then(response => {
      return response.json()
    })
  }
  
  module.exports = {
    createBlog: createBlog,
    readAllBlogs: readAllBlogs,
    updateBlog: updateBlog,
    deleteBlog: deleteBlog,
    batchDeleteBlogs: batchDeleteBlogs
  }