import React from 'react';
// import { CSSTransition } from 'react-transition-group';
import * as GrIcons from 'react-icons/gr';
import './pages-css/Contact.css';
import {useForm} from 'react-hook-form';

export default function Contact() {

    // const [appearContact] = useState(true)
    const {register, handleSubmit, errors} = useForm();

    const onSubmit = (data) => {
        console.log(data);
    }

        return (
            // <CSSTransition
            //     in={appearContact}
            //     appear={true}
            //     timeout={300}
            //     classNames="fade"
            // >
                <div className='contact-main-container clearfix'>
                    <div className='contact-details-container'>
                    <div>
                        <h3 className='knowledge-learning-padding'>Find Out More:</h3>
                        <a target="_blank" rel="noopener noreferrer" href='https://github.com/RyanLuttrell'>
                            <GrIcons.GrGithub className="social-icons github" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href='https://www.linkedin.com/in/ryan-luttrell/'>
                            <GrIcons.GrLinkedin className="social-icons linkedin" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/standardryan/'>
                            <GrIcons.GrInstagram className="social-icons instagram" />
                        </a>
                    </div>
                    </div>
                    <div className='contact-form-container'>
                        <div className='contact-form-header'>
                            <h2 className='contact-form-text'>Get In Touch!</h2>
                            <h5 className='contact-form-text'>Is there some sort of website or app that you've always been dreaming of but don't have the skills to build it?</h5>
                            <h5></h5>
                        </div>
                        <div className='contact-form-wrapper'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input type='text' placeholder='Name' name='contactName' ref={register({required: 'Name Required'})} className='contact-form-name contact-form-general'/>
                                {errors.contactName && <p className='contact-form-error'>{errors.contactName.message}</p>}
                                <input type='email' placeholder='Email' name='contactEmail' ref={register({required: 'Phone Number Required'})} className='contact-form-email contact-form-general'/>
                                {errors.contactEmail && <p className='contact-form-error'>{errors.contactEmail.message}</p>}
                                <input type='number' placeholder='Phone Number' name='contactPhone' ref={register({required: 'Email Required'})} className='contact-form-phone contact-form-general'/>
                                {errors.contactPhone && <p className='contact-form-error'>{errors.contactPhone.message}</p>}
                                <textarea placeholder='Enter Message Here' name='contactMessage' ref={register({required: 'Message Required'})} className='contact-form-message contact-form-general'/>
                                {errors.contactMessage && <p className='contact-form-error'>{errors.contactMessage.message}</p>}
                                <input type='submit' value='Submit' className='contact-form-submit'/>
                            </form>
                        </div>
                    </div>
                </div>    
            // </CSSTransition>
        )
}