import React from 'react';
import './App.css';
import {HashRouter as Router, Switch, Route,} from 'react-router-dom';
// import {CSSTransition} from 'react-transition-group';

// import {readAllKnowledge} from './utils/knowledgeAPI';

import Header from './components/Header.jsx';
import Home from './pages/Home.jsx';
// import About from './pages/About.jsx';
import Resume from './pages/Resume.jsx';
import Footer from './components/Footer.jsx';
import Project from './pages/Project.jsx';
import Contact from './pages/Contact.jsx';
import BlogArchive from './pages/BlogArchive.jsx'

function App() {

  // const [appearHome, setAppearHome] = useState(true);

// Todo data
// const firstKnowledge = {
//   skill: 'HTML5',
//   link: 'https://developer.mozilla.org/en-US/docs/Web/HTML',
//   cName: 'html knowledge'
// }

// // create it!
// createKnowledge(firstKnowledge).then((response) => {
//   console.log('API response', response)
//   // set app state
// }).catch((error) => {
//   console.log('API error', error)
// })


return (
  <div className="App">
    <div className="primary-container">
      <Router>
        <Header />
        {/* <LeftBar /> */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path='/resume' component={Resume} />
          <Route path='/projects' component={Project} />
          <Route path='/contact' component={Contact} />
          <Route path='/blog' component={BlogArchive} />
        </Switch>
        <Footer />
      </Router>
    </div>
  </div>
);
}

export default App;
