export const ExperienceData = [
    {
        title: 'Project Manager',
        company: 'True Market Design',
        location: 'Calgary, AB',
        dates: 'August 2019 - Current',
        description: 'I designed a modular frontend system that allowed multiple teams to independently build a web application used by farmers to optimize fertilizer usage.',
        duties: ['Manage client relationships during project fulfillment', 'Provide strategic insights into streamlining project deliverables for various types of digital marketing projects', 'Estimate costs and resources necessary for a variety of projects', 'Responsible for hiring, firing and performance reviews of team members' ],
        companyLink: 'https://truemarket.ca/'
    },
    {
        title: 'Founder',
        company: 'Collective Coffee Roasters',
        location: 'Calgary, AB',
        dates: 'January 2017 - Current',
        description: 'I designed a modular frontend system that allowed multiple teams to independently build a web application used by farmers to optimize fertilizer usage.',
        duties: ['Travelled to Central America to meet the farmers/producers of coffee to aid in the design and implementation of ethical and sustainable practices', 'Developed and implemented all processes necessary for the purchase, shipping, importing and roasting of all coffee', 'Created and executed a national distribution network', 'Oversaw the development and implemenation of a multi-platform marketing program. Including Google Search & Display, Facebook and Instagram'],
        companyLink: 'http://ourcollective.ca/'   
    },
    {
        title: 'Cafe Manager',
        company: 'Rosso Coffee Roasters',
        location: 'Calgary, AB',
        dates: 'March 2016 - August 2019',
        description: 'I designed a modular frontend system that allowed multiple teams to independently build a web application used by farmers to optimize fertilizer usage.',
        duties: ['Created a people-first environment that empowered our staff to use their talents to move our company forward', 'Provided training and education to employees including orientation and development'],
        companyLink: 'https://www.rossocoffeeroasters.com/'
    },
    {
        title: 'Assistant General Manager',
        company: 'Murphy Hospitality Group',
        location: 'Charlottetown, PEI',
        dates: '',
        description: 'I designed a modular frontend system that allowed multiple teams to independently build a web application used by farmers to optimize fertilizer usage.',
        duties: ['Responsible for hiring, training, evaluating and discipline of a staff of thrity in a way that demonstrates a people-first culture.', 'Helped with the planning and opening of two new restaurants within thecompany', 'Oversaw the development and implimentation of new cocktail programs'],
        companyLink: 'https://mhgpei.com/'
    }
]