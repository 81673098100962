export const LearningData = [
    {
        skill: 'PHP',
        link: 'https://www.php.net/',
        cName: 'php knowledge'
    },
    {
        skill: 'MongoDB',
        link: 'https://www.mongodb.com/',
        cName: 'mongo knowledge'
    }
]