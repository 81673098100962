import React, {useState}from 'react';
import { CSSTransition } from 'react-transition-group';
import './pages-css/Home.css';
import Project from './Project.jsx';



function Home() {

    const [appearHome] = useState(true);

    return (
        <CSSTransition
            in={appearHome}
            appear={true}
            timeout={300}
            classNames="fade"
        >
            <div className="primary-home-container clearfix">
                <Project />
            </div>
        </CSSTransition>
    )
}

export default Home